<!-- 融合碎片 -->
<script setup>
import { ethers } from '@/utils/ethers';
import { computed, ref, reactive, watchEffect } from 'vue';
import { ElMessage } from 'element-plus';
import { useAppStore } from '@store/appStore';
import { showStakePop, setShowStake } from '@cps/PopUp/usePop.js';
import StakePop from '@cps/PopUp/StakePop.vue';
import openBoxPop from './components/openBoxPop.vue';
import RewardPop from './components/rewardPop.vue';
import { setShowOpenBox, setShowRewardPop } from './components/usePop';
import $load from '@cps/GlobalLoading';
import { useI18n } from 'vue-i18n';
import { bpMul, bpDiv, fixFloor } from '@/utils/bpMath';
import { useRoute, useRouter } from 'vue-router';
import boxObj from '@/contractsApi/polygon/HaloMintContract';
import commonObj from '@/contracts/commonContract.js';
import {
  FRAGMENT_CONT,
  HALO_BOX_MINT_CONT,
  HALO_BLIND_BOX_CONT
} from '@/contracts/polygonAddress.js';
import { toThousands } from '@/utils/tools.js';

const $tx = useI18n();
const $route = useRoute();
const router = useRouter();
const appStore = useAppStore();
const HaloMint = new boxObj();
const ShardNFT = new commonObj(FRAGMENT_CONT);
const { contract } = new commonObj(HALO_BLIND_BOX_CONT);

(async () => {
  try {
    await init();
  } catch (error) {
    console.log(error);
  }
})();

const hasAllowanceOfShard = ref(false);
const hasAllowance = ref(false);
const shardBalance = ref(0);
const HaloMartBoxInfo = ref(HaloMint.martBoxInfo);
const HaloMartBoxList = ref([]);
const pickList = ref([]);
async function init() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  hasAllowanceOfShard.value = await ShardNFT.contract.isApprovedForAll(
    appStore.defaultAccount,
    HALO_BOX_MINT_CONT.address
  );
  hasAllowance.value = await contract.isApprovedForAll(
    appStore.defaultAccount,
    HALO_BOX_MINT_CONT.address
  );
  shardBalance.value = await ShardNFT.contract.balanceOf(appStore.defaultAccount, 1);
  HaloMartBoxInfo.value = await HaloMint.checkBoxInfo();
  const res = await HaloMint.getBoxList();
  HaloMartBoxList.value = HaloMint.markBoxList;
  console.log('new..', res);
}

/**
 * 导航栏
 */
const curNav = ref({
  id: 1,
  label: 'card.18',
  name: 'cattleBox',
  type: 'cattleBox',
  active: true,
  boxImg: 'https://game.legendranch.app/legendaryranch/ticket/markBox.png'
});
const navList = reactive([
  {
    id: 1,
    label: 'card.18',
    name: 'cattleBox',
    type: 'cattleBox',
    active: true,
    boxImg: 'https://game.legendranch.app/legendaryranch/ticket/markBox.png'
  },
  {
    id: 2,
    label: 'claimAssets.7',
    name: 'cattle',
    type: 'cattle',
    active: false,
    boxImg: 'https://game.legendranch.app/legendaryranch/ticket/mysteryCalf.png'
  },
  {
    id: 3,
    label: 'claimAssets.12',
    name: 'hero',
    type: 'hero',
    active: false,
    boxImg: 'https://game.legendranch.app/legendaryranch/ticket/mysteryHero.png'
  },
  {
    id: 4,
    label: 'claimAssets.11',
    name: 'planet',
    type: 'homeland',
    active: false,
    boxImg: 'https://game.legendranch.app/legendaryranch/ticket/mysteryHomeland.png'
  }
]);

function pickNav(name) {
  navList.forEach((item, i) => {
    item.active = false;
    if (name === item.name) {
      item.active = true;
      curNav.value = item;
    }
  });
}

/**
 * 质押弹窗或者授权
 */
const isShowOpenBoxPop = ref(false);
async function handleOpen() {
  isShowOpenBoxPop.value = true;
  setShowOpenBox(true);
  await HaloMint.getBoxList();
  HaloMartBoxList.value = HaloMint.markBoxList.filter(it => {
    return pickList.value.indexOf(it.id) == -1;
  });
}

/**
 * 授权开启盲盒
 */
const openLoading = ref(false);
async function setApproveForOpen() {
  $load({ isShow: true });
  try {
    const res = await contract.setApprovalForAll(HALO_BOX_MINT_CONT.address, true);
    const result = await res.wait();
    hasAllowance.value = true;
    console.log('approve..', res, result);
    ElMessage({
      message: $tx.t('msg.3'),
      type: 'success'
    });
  } catch (error) {
    console.log(error);
    ElMessage({
      message: $tx.t('msg.4'),
      type: 'error'
    });
  }
  $load({ isShow: false });
}

/**
 * 授权合成动作,将碎片授权给开启盲盒合约
 */
const mergeLoading = ref(false);
async function setApproveForShard() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  $load({ isShow: true });
  try {
    const res = await ShardNFT.contract.setApprovalForAll(HALO_BOX_MINT_CONT.address, true);
    const result = await res.wait();
    hasAllowanceOfShard.value = true;
    console.log('approve..', res, result);
    ElMessage({
      message: $tx.t('msg.3'),
      type: 'success'
    });
  } catch (error) {
    console.log(error);
    ElMessage({
      message: $tx.t('msg.4'),
      type: 'error'
    });
  }
  $load({ isShow: false });
}

/**
 * 是否已经开完盲盒
 */
const isCanMerge = computed(() => {
  if (curNav.value.type == 'hero') {
    if (HaloMartBoxInfo.value.heroLeft < 1) return false;
  }
  if (curNav.value.type == 'homeland') {
    if (HaloMartBoxInfo.value.homelandLeft < 1) return false;
  }
  return true;
});

/**
 * 是否已经开完家园盲盒
 */
const isHomelandCanMerge = computed(() => {
  if (curNav.value.name == 'panlet') {
    if (HaloMartBoxInfo.value.homeLeft < 1) return false;
  }
  return true;
});

/**
 * 是否开启合成弹窗
 */
function handleShowMergePop() {
  if (isCanMerge.value) {
    setShowStake(true);
  }
}

/**
 * 跳转购买页;如果是星球项则合成家园星球
 */
function handleBuyNow() {
  router.push({ name: 'buyBox' });
}

/**
 * 选择开启盲盒
 */
const HaloRewardList = ref([]);
async function handleComfirmOpen(list) {
  if (!hasAllowance.value) {
    await setApproveForOpen();
    return;
  }
  if (!list?.length) {
    ElMessage({
      message: $tx.t('msg.37'),
      type: 'error'
    });
    return;
  }
  $load({ isShow: true, style: 'openMartBox' });
  try {
    HaloRewardList.value = await HaloMint.openMarkBox(list);
    pickList.value = list;
    setShowOpenBox(false);
    setShowRewardPop(true);
  } catch (err) {
    let info = err?.data?.message || err?.message;
    // 点击了拒绝信息
    if (info?.includes?.('User denied transaction')) {
      info = 'User denied transaction signature.';
    }

    // 避免信息太长看懵用户
    info = String(info).length > 100 ? 'error' : info;
    ElMessage({
      type: 'error',
      message: info
    });
    pickList.value = [];
    console.log(err);
  }
  openLoading.value = false;
  $load({ isShow: false, style: 'openMartBox' });
}

/**
 * 根据轮次选择投入碎片数量及显示文字
 */
const shardNeed = ref(0); // 需要投入的碎片数量
const remainAmount = ref(0); // 剩余量
const probability = ref(5); // 概率
const heroRound = ref(1);
watchEffect(() => {
  if (curNav.value.name === 'cattleBox') {
    shardNeed.value = 5;
  } else if (curNav.value.name === 'cattle') {
    shardNeed.value = 10;
  } else if (curNav.value.name === 'planet') {
    shardNeed.value = 20;
  } else if (curNav.value.name === 'homeplanet') {
    shardNeed.value = 50;
  } else if (curNav.value.name === 'hero') {
    remainAmount.value = HaloMartBoxInfo.value.heroLeft;
    heroRound.value = HaloMartBoxInfo.value.heroRound + 1;
    if (heroRound.value === 1) {
      probability.value = 5;
      shardNeed.value = 10;
    } else if (heroRound.value === 2) {
      probability.value = 20;
      shardNeed.value = 20;
    } else if (heroRound.value === 3) {
      probability.value = 50;
      shardNeed.value = 40;
    } else if (heroRound.value === 4) {
      probability.value = 100;
      shardNeed.value = 80;
    }
  }
});

/**
 * 确认合成
 */
const isClickConfirm = ref(false);
async function handleConfirmMerge() {
  // 授权
  if (hasAllowanceOfShard.value === false) {
    return;
  }
  // 判斷是否點擊質押
  if (hasAllowanceOfShard.value === true && isClickConfirm.value === false) {
    isClickConfirm.value = true;
    return;
  }
  // 判断碎片数量是否充足
  if (curNav.value.type == 'genland') {
    if (Number(shardBalance.value) < 20) {
      ElMessage({
        message: $tx.t('msg.13'),
        type: 'error'
      });
      return;
    }
  } else if (curNav.value.type == 'homeland') {
    if (Number(shardBalance.value) < 50) {
      ElMessage({
        message: $tx.t('msg.13'),
        type: 'error'
      });
      return;
    }
  } else {
    if (Number(shardBalance.value) < Number(shardNeed.value)) {
      ElMessage({
        message: $tx.t('msg.13'),
        type: 'error'
      });
      return;
    }
  }

  $load({ isShow: true });
  try {
    HaloRewardList.value = await HaloMint.openExtractBox(curNav.value.type);
    await init();
    setShowStake(false);
    setShowRewardPop(true);
  } catch (err) {
    let info = err?.data?.message || err?.message;
    // 点击了拒绝信息
    if (info?.includes?.('User denied transaction')) {
      info = 'User denied transaction signature.';
    }

    // 避免信息太长看懵用户
    info = String(info).length > 100 ? 'error' : info;
    ElMessage({
      type: 'error',
      message: info
    });
    console.log(err);
  }
  $load({ isShow: false });
}
</script>

<template>
  <div class="blind-box-wrap">
    <img
      src="https://game.legendranch.app/legendaryranch/banner/merge-banner.png"
      alt=""
      class="banner"
    />

    <!-- 主要内容 -->
    <section class="main">
      <!-- nav -->
      <div class="nav-wrap">
        <div
          :class="['navs', { active: nav.active && curNavType === nav.name }]"
          v-for="nav in navList"
          :key="nav.id"
          @click="pickNav(nav.name)"
        >
          <div :class="['title', { active: nav.active }]">
            {{ $t(nav.label) }}
          </div>
        </div>
      </div>

      <!-- merge -->
      <section class="merge-container">
        <div class="head-wrap">
          <div class="title">{{ $t(curNav.label) }}</div>
        </div>
        <!-- 盒子 -->
        <div class="box-wrap">
          <div class="product-wrap">
            <img :src="curNav.boxImg" alt="" class="mystery" />
          </div>
        </div>
        <!-- 前三项共用布局 -->
        <div class="normal-content-wrap" v-show="curNav.name !== 'planet'">
          <div class="number" v-show="curNav.name == 'hero'">
            {{ $t('halo.49') }} {{ remainAmount }}
          </div>
          <div class="name" v-show="curNav.name == 'hero'">{{ $t('halo.34') }} {{ heroRound }}</div>
          <div class="label" v-show="curNav.name == 'hero'">
            {{ $t('halo.51') }} {{ probability }}%
          </div>
          <div class="label" v-show="curNav.name == 'hero'">
            {{ $t('halo.48') }} {{ shardNeed }}
          </div>
          <div class="amount" v-show="curNav.name !== 'hero'">
            {{ $t('halo.48') }} {{ shardNeed }}
          </div>
          <span class="remain">{{ $t('halo.47') }} {{ +shardBalance }} pcs</span>
        </div>
        <!-- 星球布局 -->
        <div class="content-wrap" v-show="curNav.name == 'planet'">
          <div class="label-wrap">
            <div class="number">{{ $t('halo.49') }} {{ HaloMartBoxInfo.homelandLeft }}</div>
            <div class="label">{{ $t('home.18') }}</div>
            <div class="label">{{ $t('halo.50') }} 7.5%</div>
            <div class="label">{{ $t('halo.52') }} 50</div>
            <span class="label" v-show="curNav.name == 'planet'"
              >{{ $t('halo.47') }} {{ +shardBalance }} pcs</span
            >
          </div>
        </div>
        <!-- btn -->
        <div class="btn-wrap">
          <button class="btn" @click="handleShowMergePop">
            {{ isCanMerge ? $t('halo.53') : $t('dialog.15') }}
          </button>
          <button class="btn" @click="handleBuyNow">
            {{ $t('halo.54') }}
          </button>
          <button class="btn" v-show="curNav.name == 'cattleBox'" @click="handleOpen">
            {{ $t('halo.55') }}
          </button>
        </div>
      </section>

      <!-- rules -->
      <section class="rules-container">
        <div class="rules-title">{{ $t('claimAssets.27') }}</div>
        <ul v-show="curNav.name == 'cattleBox'">
          <li>{{ $t('halo.62') }}</li>
          <li>{{ $t('halo.63') }}</li>
          <li>{{ $t('halo.64') }}</li>
        </ul>
        <table v-show="curNav.name == 'cattleBox'">
          <tr>
            <th>{{ $t('halo.56') }}</th>
            <th>{{ $t('halo.57') }}</th>
            <th>{{ $t('igo.3') }}</th>
          </tr>
          <tr>
            <td rowspan="4">5 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.18') }}</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>{{ $t('halo.60') }}</td>
            <td>80%</td>
          </tr>
        </table>

        <ul v-show="curNav.name == 'cattle'">
          <li>{{ $t('halo.65') }}</li>
          <li>{{ $t('halo.66') }}</li>
        </ul>
        <table v-show="curNav.name == 'cattle'">
          <tr>
            <th>{{ $t('halo.56') }}</th>
            <th>{{ $t('halo.57') }}</th>
            <th>{{ $t('igo.3') }}</th>
          </tr>
          <tr>
            <td rowspan="4">10 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.19') }}</td>
            <td>15%</td>
          </tr>
          <tr>
            <td>{{ $t('halo.61') }}</td>
            <td>85%</td>
          </tr>
        </table>

        <ul v-show="curNav.name == 'hero'">
          <li>{{ $t('halo.67') }}</li>
          <li>{{ $t('halo.68') }}</li>
          <li>{{ $t('halo.69') }}</li>
        </ul>
        <table v-show="curNav.name == 'hero'">
          <tr>
            <th>{{ $t('halo.58') }}</th>
            <th>{{ $t('halo.56') }}</th>
            <th>{{ $t('halo.57') }}</th>
            <th>{{ $t('igo.3') }}</th>
          </tr>
          <tr>
            <td rowspan="4">1</td>
          </tr>
          <tr>
            <td rowspan="4">10 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.20') }}</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>50,000 BVG</td>
            <td>95%</td>
          </tr>
          <tr>
            <td rowspan="4">2</td>
          </tr>
          <tr>
            <td rowspan="4">20 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.20') }}</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>100,000 BVG</td>
            <td>80%</td>
          </tr>
          <tr>
            <td rowspan="4">3</td>
          </tr>
          <tr>
            <td rowspan="4">40 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.20') }}</td>
            <td>50%</td>
          </tr>
          <tr>
            <td>200,000 BVG</td>
            <td>50%</td>
          </tr>
          <tr>
            <td rowspan="4">4</td>
          </tr>
          <tr>
            <td rowspan="4">80 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.20') }}</td>
            <td>100%</td>
          </tr>
        </table>

        <ul v-show="curNav.name == 'planet'">
          <li>{{ $t('halo.70') }}</li>
          <li>{{ $t('halo.71') }}</li>
        </ul>
        <table v-show="curNav.name == 'planet'">
          <tr>
            <th>{{ $t('halo.56') }}</th>
            <th>{{ $t('halo.57') }}</th>
            <th>{{ $t('igo.3') }}</th>
          </tr>
          <tr>
            <td rowspan="3">50 {{ $t('halo.59') }}</td>
          </tr>
          <tr>
            <td>{{ $t('card.22') }}</td>
            <td>7.5%</td>
          </tr>
          <tr>
            <td>500,000 BVG</td>
            <td>92.5%</td>
          </tr>
        </table>

        <div class="tip" @click="router.push({ name: 'cattleMartEvent' })">{{ $t('halo.45') }}</div>
      </section>
    </section>
  </div>

  <!-- 选择NFT弹窗 -->
  <openBoxPop
    :isApprove="hasAllowance"
    :boxList="HaloMartBoxList"
    @comfirm="handleComfirmOpen"
  ></openBoxPop>

  <!-- 奖励弹窗 -->
  <RewardPop :rewardList="HaloRewardList"></RewardPop>

  <!-- 合成确认弹窗 -->
  <StakePop
    :isStake="isClickConfirm"
    :isApprove="hasAllowanceOfShard"
    :title="'detail.54'"
    :stepTitle="['detail.55', 'detail.59']"
    :stepDesc="['detail.56', 'detail.60']"
    @approve="setApproveForShard"
    @stake="handleConfirmMerge"
  ></StakePop>
</template>

<style lang="scss" scoped>
$mobGap: 0.2rem; // 移动端两端的padding

.blind-box-wrap {
  color: #fff;
  width: 100%;
  min-height: 100vh;
  background-image: url('https://game.legendranch.app/legendaryranch/nft/bg-blindBox-star.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // @include -media($phone, height, 100vh);
}

.banner {
  width: 100%;
  position: relative;
  z-index: 9;
  @include -height(3.16rem, 3.6rem, auto);
  object-fit: cover;
}

.main {
  position: relative;
  padding: 0 $mobGap 1.2rem;
  z-index: 9;
  @include -font-size(0.14rem, 0.24rem, 0.24rem);

  .nav-wrap {
    @include -width(auto, auto, 11.74rem);
    margin: 0 auto 0;
    @include flexPos(space-around, center);
    .navs {
      @include flexPos(space-around, center);
      .title {
        @include -font-size(0.24rem, 0.24rem, 0.24rem);
        @include flexPos(center);
        flex-direction: column;

        &.active {
          color: #fdff46;
          font-weight: bold;
        }
      }
    }
  }

  .rules-title {
    font-size: 0.3rem;
    font-weight: bold;
    text-align: center;
  }

  .btns {
    width: 100%;
    @include flexPos(center);
    button {
      width: 2.9rem;
      height: 0.7rem;
      background-image: url('~@img/nft/bg-btn.png');
      background-size: 100% 100%;
      background-color: transparent;
      color: #f9cb22;
      @include -font-size(0.26rem, 0.26rem, 0.36rem);
      font-weight: bold;
    }
  }

  .merge-container {
    @include -width(7.15rem, auto, 11.74rem);
    margin: 0 auto 0;
    font-size: 0.24rem;
    background-image: url('~@img/nft/bg-merge-box.png');
    background-size: 100% 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    padding: 0.8rem 1.07rem;
    @include -media($phone, padding, 0.5rem 0.4rem);
    @include flexPos(center);
    flex-direction: column;

    .head-wrap {
      @include flexPos(center);
      width: 100%;
      position: relative;
      .title {
        font-size: 0.3rem;
        line-height: 0.58rem;
        @include -media($phone, line-height, 0.36rem);
        font-weight: bold;
      }
    }

    .box-wrap {
      @include flexPos(space-around, center);
      width: 100%;
      margin-top: 0.15rem;

      .product-wrap {
        @include -width(1.52rem, 2.5rem, 2.5rem);
        @include -height(1.52rem, 2.5rem, 2.5rem);
        @include flexPos(center);
        background-image: url('~@img/nft/bg-product.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .mystery {
          @include -width(1rem, 100%, 100%);
          // @include -height(1.24rem, 100%, 100%);
        }
      }
    }
    .content-wrap {
      @include flexPos(space-around, center);
      width: 100%;
      .label-wrap {
        @include flexPos(flex-start, center);
        flex-direction: column;
        margin-bottom: 0.5rem;
      }
    }
    .normal-content-wrap {
      @include flexPos(center);
      flex-direction: column;
    }
    .name {
      @include -font-size(0.24rem, 0.24rem, 0.24rem);
      line-height: 0.58rem;
    }
    .number {
      @include -font-size(0.22rem, 0.22rem, 0.18rem);
      margin: 0.15rem 0;
    }
    .label {
      @include -font-size(0.26rem, 0.24rem, 0.24rem);
      line-height: 0.36rem;
    }
    .amount {
      margin-top: 0.5rem;
      @include -font-size(0.26rem, 0.24rem, 0.24rem);
    }
    .remain {
      margin-bottom: 0.48rem;
      line-height: 0.48rem;
      @include -font-size(0.26rem, 0.24rem, 0.24rem);
    }
    .last {
      margin: 0.5rem 0 0.2rem 0;
      @include -font-size(0.26rem, 0.24rem, 0.24rem);
    }
    .rate {
      @include -font-size(0.26rem, 0.24rem, 0.24rem);
      line-height: 0.36rem;
      // @include -padding-top(0, 0.1rem, 0.1rem);
      // @include -padding-bottom(0, 0.1rem, 0.1rem);
      .num {
        font-size: 0.36rem;
        font-weight: bold;
        color: #6298db;
      }
    }

    .btn-wrap {
      width: 100%;
      @include flexPos(space-around, center);

      button {
        @include -width(2rem, 2.9rem, 2.9rem);
        @include -height(auto, 0.7rem, 0.7rem);
        @include flexPos(center);
        color: #6298db;
        font-weight: bold;
        padding: 0.1rem 0.1rem;
        @include -font-size(0.24rem, 0.24rem, 0.24rem);
        @include -media($pc, padding, 0);
        background-image: url('~@img/nft/bg-confirm-btn.png');
        background-size: 100% 100%;
        background-color: transparent;
        background-repeat: no-repeat;
      }
    }
  }
}

.buy-btn {
  @include -width(2.5rem, 2.9rem, 2.9rem);
  @include -height(auto, 0.7rem, 0.7rem);
  @include flexPos(center);
  color: #6298db;
  font-weight: bold;
  padding: 0.1rem 0.2rem;
  @include -media($pc, padding, 0);
  background-image: url('~@img/nft/bg-confirm-btn.png');
  background-size: 100% 100%;
  background-color: transparent;
  background-repeat: no-repeat;
}

.rules-container {
  @include -width(auto, auto, 11rem);
  margin: 0 auto 0;

  li {
    // margin-top: 0.3rem;
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    line-height: 0.48rem;
    @include -media($phone, line-height, 0.36rem);
  }

  table {
    margin: 0.22rem auto 0.26rem;
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    th {
      text-align: center;
      height: 0.5rem;
      border: solid 1px #fff;
    }

    td {
      // text-align: center;
      padding: 0 0.4rem;
      height: 0.5rem;
      border: solid 1px #fff;
    }
  }
}
.tip {
  @include -font-size(0.24rem, 0.24rem, 0.18rem);
  text-align: center;
  margin-top: 0.5rem;
  cursor: pointer;
}

.img-wrap {
  @include flexPos(center);
  flex-direction: column;

  .img {
    width: 1.26rem;
    height: 1.7rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>
