<script setup>
import { defineProps, defineEmits, ref, computed, watchEffect } from 'vue';
import { showOpenBoxPop, setShowOpenBox } from './usePop';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  title: {
    type: String,
    default: 'blindBox.48'
  },
  boxList: {
    type: Array,
    default: []
  },
  isApprove: {
    type: Boolean,
    default: false
  }
});
const $tx = useI18n();
const $emits = defineEmits(['comfirm']);

/**
 * 选择卡牌的数量列表
 */
const checkList = ref([]);
const cattleBoxList = ref([]);

watchEffect(() => {
  cattleBoxList.value = props.boxList;
  if (!showOpenBoxPop.value) {
    handleClose();
  }
  console.log('props..', cattleBoxList.value);
});

(async () => {
  try {
    console.log('xxxxx...', props.boxList);
  } catch (error) {
    console.log(error);
  }
})();

/**
 * 选择的卡牌ID
 */
const checkedIds = computed(() => {
  return checkList.value.map(it => it.id);
});

/**
 * 点击选择
 */
function handleClick(item, index) {
  cattleBoxList.value[index].check = item.check;
  checkList.value = cattleBoxList.value.filter(item => item.check);
  if (checkList.value.length > 10) {
    cattleBoxList.value[index].check = false;
    checkList.value = cattleBoxList.value.filter(item => item.check);
    ElMessage({
      message: $tx.t('msg.35'),
      type: 'error'
    });
    return;
  }
  console.log('check..', checkedIds.value);
}

/**
 * 关闭按钮
 */
function handleClose() {
  setShowOpenBox(false);
  for (let index = 0; index < cattleBoxList.value.length; index++) {
    cattleBoxList.value[index].check = false;
  }
  checkList.value = cattleBoxList.value.filter(item => item.check);
}
</script>

<template>
  <van-popup v-model:show="showOpenBoxPop" closeable @click-close-icon="handleClose">
    <div class="dialog-wrap">
      <div class="check-num">{{ `${checkList.length}/10` }}</div>
      <!-- 内容 -->
      <div class="container-wrap" v-if="cattleBoxList?.length">
        <label class="card" v-for="(item, index) in cattleBoxList" :key="index">
          <input
            class="card__input"
            type="checkbox"
            v-model="item.check"
            @change="handleClick(item, index)"
          />
          <div class="card__body">
            <div class="card__body-cover">
              <img class="card__body-cover-image" :src="item.image" />
              <span class="card__body-cover-chackbox">
                <svg class="card__body-cover-chackbox--svg" viewBox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </svg>
              </span>
            </div>
            <header class="card__body-header">
              <div class="card__body-header-title">{{ `${item.id}` }}</div>
              <div class="card__body-header-subtitle">{{ `${$t(item.name)}` }}</div>
            </header>
          </div>
        </label>
      </div>
      <van-empty
        v-else
        class="empty"
        description="You don't have any cards yet"
        :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
      />
      <!-- 确定按钮 -->
      <div
        class="btn"
        @click="$emits('comfirm', checkedIds)"
        v-loading="openLoading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
      >
        {{ !props.isApprove ? $t('common.1') : $t('dialog.1') }}
      </div>
    </div>
  </van-popup>
</template>

<style lang="scss" scoped>
@import './index.scss';
</style>
