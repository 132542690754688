<!-- 牛牛盲盒 -->
<script setup>
import { computed, ref, reactive, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import $load from '@cps/GlobalLoading';
import { useI18n } from 'vue-i18n';
import BpSwiper from '@cps/BpSwiper';
import { SwiperSlide } from 'swiper/vue';
import {
  USDT_TOKEN_CONT,
  HALO_BOX_MINT_CONT,
  HALO_BLIND_BOX_CONT
} from '@/contracts/polygonAddress.js';
import CoinToken from '@/contracts/CoinToken.js';
import commonObj from '@/contracts/commonContract.js';
import boxObj from '@/contractsApi/polygon/HaloMintContract';
import { toThousands, plusXing, handleCopy } from '@/utils/tools.js';
import { showStakePop, setShowStake } from '@cps/PopUp/usePop.js';
import StakePop from '@cps/PopUp/StakePop.vue';
import openBoxPop from './components/openBoxPop.vue';
import RewardPop from './components/rewardPop.vue';
import { bpMul, bpDiv, bpGt } from '@/utils/bpMath';
import { setShowOpenBox, setShowRewardPop } from './components/usePop';

const $tx = useI18n();
const $route = useRoute();
const router = useRouter();
const appStore = useAppStore();
const { contract } = new commonObj(HALO_BOX_MINT_CONT);
const HaloBox = new commonObj(HALO_BLIND_BOX_CONT);
const usdtToken = new CoinToken(USDT_TOKEN_CONT);
const HaloMint = new boxObj();

const isWhite = ref(false);
const supply = ref(0);
const balance = ref(0);
const referAddr = ref(null);
const referVal = ref(null);
const pickList = ref([]);
const HaloBoxList = ref([]);
const hasAllowanceForUsdt = ref(false);
const hasAllowanceForNFT = ref(false);
(async () => {
  try {
    await init();
  } catch (error) {
    console.log(error);
  }
})();

onMounted(() => {
  handleCopy();
});

/**
 * 滑动模块配置
 */
const swiper_options = computed(() => {
  return {
    autoplay: {
      disableOnInteraction: true, // 鼠标滑动后继续自动播放
      delay: 3000 // 4秒切换一次
    },
    slidesPerView: appStore.curDevice === 'phone' ? 3 : 5,
    spaceBetween: 30,
    navigation: {
      prevEl: '.blind-box-prev-btn',
      nextEl: '.blind-box-next-btn'
    }
  };
});

const rulesImg = reactive([
  {
    url: 'https://game.legendranch.app/legendaryranch/ticket/mysteryHero.png'
  },
  {
    url: 'https://game.legendranch.app/legendaryranch/ticket/little-cow.png'
  },
  {
    url: 'https://game.legendranch.app/legendaryranch/ticket/little-bullt.png'
  },
  {
    url: 'https://game.legendranch.app/legendaryranch/ticket/markBox.png'
  },
  {
    url: 'https://game.legendranch.app/legendaryranch/ticket/shard.png'
  }
]);

async function init() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  hasAllowanceForUsdt.value = await usdtToken.allow(
    appStore.defaultAccount,
    HALO_BOX_MINT_CONT.address
  );
  hasAllowanceForNFT.value = await HaloBox.contract.isApprovedForAll(
    appStore.defaultAccount,
    HALO_BOX_MINT_CONT.address
  );

  balance.value = await usdtToken.getBalance();
  const res = await HaloMint.checkBoxInfo();

  supply.value = res.boxNum;
  isWhite.value = res.isWhite;
  if (+res.invitor) {
    referVal.value = res.invitor;
  }
  await HaloMint.getBoxList();
  HaloBoxList.value = HaloMint.haloBoxList;
  console.log('ref..', referVal.value);
  console.log('list..', HaloBoxList.value, HaloMint.haloBoxList.length);
}

/**
 * 开启购买的弹窗
 */
async function handleOpenBuyPop() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  setShowStake(true);
}

/**
 * 设置Approve
 */
async function setApproveForUsdt() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  $load({ isShow: true });
  try {
    const approve = await usdtToken.auth(HALO_BOX_MINT_CONT.address);
    hasAllowanceForUsdt.value = true;
  } catch (error) {
    console.log(error);
    ElMessage({
      message: $tx.t('msg.4'),
      type: 'error'
    });
  }

  $load({ isShow: false });
}

/**
 * 开启盲盒的弹窗
 */
async function handleOpenBoxPop() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  setShowOpenBox(true);
  await HaloMint.getBoxList();
  // 默认剔除已经开启的盲盒
  HaloBoxList.value = HaloMint.haloBoxList.filter(it => {
    return pickList.value.indexOf(it.id) == -1;
  });
}

/**
 * 授权
 */
const openLoading = ref(false);
async function setApproveForNFT() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  $load({ isShow: true });
  try {
    const res = await HaloBox.contract.setApprovalForAll(HALO_BOX_MINT_CONT.address, true);
    const result = await res.wait();
    hasAllowanceForNFT.value = true;
    console.log('approve..', res, result);
    ElMessage({
      message: $tx.t('msg.3'),
      type: 'success'
    });
  } catch (error) {
    console.log(error);
    ElMessage({
      message: $tx.t('msg.4'),
      type: 'error'
    });
  }
  $load({ isShow: false });
}

/**
 * 选择开启项
 */
const HaloRewardList = ref([]);
async function handleComfirm(list) {
  if (!hasAllowanceForNFT.value) {
    setApproveForNFT();
    return;
  }
  if (!list?.length) {
    ElMessage({
      message: $tx.t('msg.37'),
      type: 'error'
    });
    return;
  }
  $load({ isShow: true, style: 'openHaloBox' });
  try {
    HaloRewardList.value = await HaloMint.openBox(list);
    // 默认剔除已经开启的盲盒
    pickList.value = list;
    setShowOpenBox(false);
    setShowRewardPop(true);
  } catch (error) {
    ElMessage({
      message: $tx.t('msg.34'),
      type: 'error'
    });
    pickList.value = [];
  }
  $load({ isShow: false, style: 'openHaloBox' });
}

/**
 * 我要打十个
 */
const buyNum = ref(1);
function pulsTen() {
  buyNum.value += 10;
  if (buyNum.value > 20) {
    buyNum.value = 20;
  }
}
/**
 * 价格
 */
const prices = computed(() => {
  if (buyNum.value > 20) {
    buyNum.value = 20;
  }
  // return Number(+HaloMint.martBoxInfo.boxPrice * buyNum.value);
  return 20 * buyNum.value;
});
/**
 * 白名单价格
 */
const whitePrices = computed(() => {
  if (buyNum.value > 20) {
    buyNum.value = 20;
  }
  if (isWhite.value) {
    return 20 * 0.9 * buyNum.value;
  } else {
    // return Number(+HaloMint.martBoxInfo.boxPrice * buyNum.value);
    return 20 * buyNum.value;
  }
});
/**
 * 处理购买数量
 * @param {String} isAdd true: 加，false: 减
 */
function handleNum(isAdd) {
  if (isAdd && buyNum.value < 20) {
    buyNum.value += 1;
  }

  if (!isAdd && buyNum.value > 1) {
    buyNum.value -= 1;
  }
}

// 禁止输入字符串
const valueChange = e => {
  if (+referVal.value) {
    referAddr.value = null;
    return;
  }
};

const buyNumChange = e => {
  if (!+buyNum.value) {
    buyNum.value = 1;
    return;
  }
  buyNum.value = Math.floor(Number(buyNum.value));
  console.log('val..', e);
  if (buyNum.value > 20) {
    buyNum.value = 20;
  }
};

/**
 * 查看推荐人地址是否合法
 * 地址为空则返回true
 */
async function checkIsRefer(addr) {
  if (!addr) return true;
  const { ethers } = appStore.ethObj;
  const isRightAddr = ethers.utils.isAddress(addr);
  if (!isRightAddr) return isRightAddr;
  const { isRefer } = await contract.normalInfo(addr);
  return isRefer;
}

async function paste() {
  if (+referVal.value) {
    referAddr.value = null;
    return;
  }
  try {
    const text = await navigator.clipboard.readText();
    console.log('Pasted content: ', text);
    referAddr.value = text.trim();
  } catch (err) {
    console.error('Failed to read clipboard contents: ', err);
  }
}

/**
 * 购买
 */
const isClickConfirm = ref(false);
async function handleBuyNow() {
  // 授权
  if (hasAllowanceForUsdt.value === false) {
    return;
  }
  // 判斷是否點擊質押
  if (hasAllowanceForUsdt.value === true && isClickConfirm.value === false) {
    isClickConfirm.value = true;
    return;
  }
  if (buyNum.value < 1) {
    return;
  }
  // 余额是否充足
  const isEnough = usdtToken.isBalanceEnough(whitePrices.value);
  if (!isEnough) {
    ElMessage({
      message: $tx.t('msg.13'),
      type: 'error'
    });
    return;
  }
  // 推荐人地址是否合法
  if (referAddr.value) {
    referVal.value = referAddr.value;
  }
  const isRefer = await checkIsRefer(referVal.value);
  if (!isRefer) {
    referVal.value = null;
    ElMessage({
      message: $tx.t('msg.33'),
      type: 'error'
    });
    return;
  }
  if (!+referVal.value) {
    referVal.value = '0x0000000000000000000000000000000000000000';
  }
  // 购买
  $load({ isShow: true });
  try {
    const resp = await contract.buyBox(buyNum.value, referVal.value);
    await resp.wait();
    buyNum.value = 1;
    referAddr.value = null;
    referVal.value = null;
    await init();
    ElMessage({
      message: $tx.t('msg.16'),
      type: 'success'
    });
  } catch (err) {
    if (err?.data?.message) {
      ElMessage({
        type: 'error',
        message: err?.data?.message
      });
    } else {
      ElMessage({
        message: 'Buy Failed',
        type: 'error'
      });
    }
    referVal.value = null;
  }
  setShowStake(false);
  $load({ isShow: false });
}
</script>

<template>
  <div class="blind-box-wrap">
    <img
      src="https://game.legendranch.app/legendaryranch/nft/ban-buy-box.png"
      alt=""
      class="banner"
    />
    <img
      src="https://game.legendranch.app/legendaryranch/nft/bg-blindBox-star.png"
      alt=""
      class="bg-blindBox-star"
    />

    <!-- 主要内容 -->
    <section class="main">
      <!-- rules -->
      <section class="rules-container">
        <div class="rules-title">{{ $t('card.31') }}</div>
        <p class="rule-info" v-html="$t('halo.37')"></p>

        <!-- contract -->
        <div class="contract-wrap">
          <span class="name">{{ $t('claimAssets.14') }}</span>
          <div class="addr-wrap">
            <span class="addr">{{ plusXing(HALO_BLIND_BOX_CONT.address, 8, 8) }}</span>
            <i
              :data-clipboard-text="HALO_BLIND_BOX_CONT.address"
              class="cpy-btn iconfont icon-cpy"
            ></i>
          </div>
        </div>
        <!-- Price -->
        <div class="label-wrap">
          <span class="name">{{ $t('claimAssets.15') }}</span>
          <span class="val">{{ toThousands(20000, 0) }}</span>
        </div>
        <div class="label-wrap">
          <span class="name">{{ $t('blindBox.1') }}</span>
          <span class="val">{{ `${20} USDT` }}</span>
        </div>

        <!-- referral address -->
        <div class="refer-wrap">
          <span class="name">{{ $t('halo.38') }}</span>
          <div class="inp-wrap">
            <input
              type="text"
              v-model="referAddr"
              class="inp"
              @input="valueChange"
              :placeholder="!+referVal ? '' : $t('msg.36')"
            />
            <button @click="paste">{{ $t('halo.39') }}</button>
          </div>
        </div>
        <div class="tip">{{ $t('halo.40') }}</div>

        <div class="issue-wrap">
          <!-- 价格 -->
          <div class="price-wrap">
            <div class="label-wrap">
              <div class="label">{{ $t('halo.41') }}</div>
              <div class="num">{{ prices }} USDT</div>
            </div>
            <div class="label-wrap">
              <div class="label">{{ $t('halo.42') }}</div>
              <div class="num">{{ whitePrices }} USDT</div>
            </div>
          </div>

          <!-- 购买数量 -->
          <div class="block-wrap">
            <div class="label">{{ $t('igo.2') }}</div>
            <div class="btn-warp">
              <button class="btn sub" @click="handleNum(false)">-</button>
              <input
                type="number"
                placeholder="1"
                class="buy-num"
                @input="buyNumChange"
                v-model="buyNum"
              />
              <button class="btn plus" @click="handleNum(true)">+</button>
            </div>
          </div>
        </div>
      </section>

      <div class="btns">
        <!-- <button v-if="Number(supply) < 1">
          {{ $t('dialog.15') }}
        </button> -->
        <button @click="handleOpenBuyPop">
          {{ $t('halo.19') }}
        </button>
        <button @click="pulsTen">{{ $t('halo.43') }}</button>
        <button @click="handleOpenBoxPop">
          {{ $t('halo.44') }}
        </button>
      </div>

      <div class="bottom-tip">{{ $t('halo.46') }}</div>

      <!-- rules -->
      <div class="blindbox-container">
        <div class="rules-title">{{ $t('halo.22') }}</div>
        <div class="cattle-list">
          <BpSwiper :option="swiper_options">
            <swiper-slide v-for="(it, inx) in rulesImg" :key="inx">
              <img :src="it.url" class="cattle-img" />
            </swiper-slide>
          </BpSwiper>
          <div>
            <i class="iconfont icon-simple-arrow blind-box-prev-btn"></i>
            <i class="iconfont icon-simple-arrow blind-box-next-btn"></i>
          </div>
        </div>
      </div>

      <div class="back-btn" @click="router.push({ name: 'cattleMartEvent' })">
        {{ $t('halo.45') }}
      </div>
    </section>
  </div>

  <openBoxPop
    :isApprove="hasAllowanceForNFT"
    :boxList="HaloBoxList"
    @comfirm="handleComfirm"
  ></openBoxPop>

  <!-- 奖励弹窗 -->
  <RewardPop :rewardList="HaloRewardList"></RewardPop>

  <!-- 购买弹窗 -->
  <StakePop
    :isStake="isClickConfirm"
    :isApprove="hasAllowanceForUsdt"
    :title="'detail.54'"
    :stepTitle="['detail.55', 'detail.57']"
    :stepDesc="['detail.56', 'detail.58']"
    @approve="setApproveForUsdt"
    @stake="handleBuyNow"
  ></StakePop>
</template>

<style lang="scss" scoped>
$mobGap: 0.2rem; // 移动端两端的padding

.blind-box-wrap {
  position: relative;
  color: #fff;
}

.banner {
  width: 100%;
  position: relative;
  z-index: 9;
  @include -height(5.6rem, 5.6rem, auto);
  object-fit: cover;
}

.bg-blindBox-star {
  width: 100%;
  height: calc(100% - 2rem);
  @include absPos(2rem, '', '', 0);
}

.main {
  width: 100%;
  position: relative;
  padding: 0 $mobGap 0.8rem;
  z-index: 9;

  .rules-title {
    font-size: 0.48rem;
    font-weight: bold;
    text-align: center;
    background: linear-gradient(161deg, #20e1ef 0%, #239bcd 99.5849609375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contract-wrap {
    width: 100%;
    @include flexPos(flex-start, center);
    margin: 0.58rem 0 0.43rem 0;
    .name {
      @include -width(1.5rem, 1.5rem, 1.2rem);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      font-weight: bold;
      line-height: 0.3rem;
    }
    .addr-wrap {
      @include flexPos(flex-start, center);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      i {
        margin-left: 0.1rem;
      }
    }
  }

  .label-wrap {
    width: 100%;
    @include flexPos(flex-start, center);
    .name {
      @include -width(1.5rem, 1.5rem, 1.2rem);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      font-weight: bold;
      line-height: 0.3rem;
    }
    .val {
      @include -font-size(0.26rem, 0.26rem, 0.22rem);
      font-weight: bold;
    }
  }

  .btns {
    width: 100%;
    @include flexPos(center);
    button {
      &:first-child {
        margin-right: 0.62rem;
      }
      &:last-child {
        margin-left: 0.62rem;
      }
      width: 2.9rem;
      height: 0.7rem;
      background-image: url('~@img/nft/bg-btn.png');
      background-size: 100% 100%;
      background-color: transparent;
      color: #f9cb22;
      @include -font-size(0.18rem, 0.26rem, 0.24rem);
      font-weight: bold;
    }
  }

  .rules-container {
    @include -width(auto, auto, 7.75rem);
    margin: -0.9rem auto 0;

    .rule-info {
      margin-top: 0.38rem;
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
      line-height: 0.36rem;
      font-family: Alibaba PuHuiTi 2;
    }

    .refer-wrap {
      width: 100%;
      margin-top: 0.47rem;
      @include flexPos(space-between, center);
      .name {
        @include -width(1.5rem, 1.5rem, 1.5rem);
        @include -font-size(0.24rem, 0.24rem, 0.18rem);
        font-weight: bold;
        line-height: 0.85rem;
        @include -media($phone, line-height, 0.3rem);
      }

      .inp-wrap {
        @include flexPos(space-between, center);
        @include -width(5.8rem, 5.8rem, calc(100% - 1.5rem));
        height: 0.4rem;
        margin-left: 0.2rem;
        border: 1px solid #ffffff;
        .inp {
          padding-left: 0.2rem;
          background-color: transparent;
          @include -width(4rem, 4.7rem, 4.7rem);
          height: 100%;
          border: none;
        }
        input::placeholder {
          padding-left: 0.2rem;
        }
        button {
          text-align: end;
          @include -width(1.2rem, 0.8rem, 0.8rem);
          height: 100%;
          text-align: center;
        }
      }
    }

    .tip {
      width: 100%;
      text-align: end;
      @include -margin-top(0.1rem, 0, 0);
      @include -font-size(0.24rem, 0.24rem, 0.18rem);
    }
    .issue-wrap {
      @include flexPos(space-between, center);
      margin-top: 0.8rem;
      margin-bottom: 0.93rem;
      font-size: 0.24rem;

      .price-wrap {
        display: flex;
        flex-direction: column;
        @include -width(4rem, 4.5rem, 4.5rem);
        .label-wrap {
          @include flexPos(space-between, center);
          margin-top: 0.3rem;
          .label {
            @include -width(2rem, 2rem, 2rem);
            @include -font-size(0.24rem, 0.24rem, 0.18rem);
            line-height: 0.3rem;
            font-weight: bold;
          }
          .num {
            @include -font-size(0.3rem, 0.3rem, 0.3rem);
            font-weight: bold;
            color: #fffc2a;
          }
        }
      }
      .block-wrap {
        display: flex;
        flex-direction: column;

        .label {
          font-size: 0.24rem;
          margin-bottom: 0.25rem;
        }

        .num {
          font-size: 0.48rem;
          font-weight: bold;
          color: #fffc2a;
          text-align: flex-end;
        }

        .btn-warp {
          @include flexPos(center);
          flex-direction: row;
          background-color: transparent;

          .btn {
            width: 0.4rem;
            height: 0.4rem;
            @include flexPos(center);
            border: 1px solid #fff;
          }

          &.sub {
            border-radius: 3px 0px 0px 3px;
            border-right: none;
          }

          &.plus {
            border-radius: 0 3px 3px 0;
            border-left: none;
          }
          .buy-num {
            width: 1.2rem;
            text-align: center;
            background: transparent;
            border: none;
          }
        }
      }
    }
  }
}

.blindbox-container {
  @include -width(auto, auto, 12rem);
  margin: 0 auto 0.96rem;

  .blindbox-info {
    margin-top: 0.38rem;
    font-size: 0.18rem;
  }
}

:deep(.cattle-list) {
  position: relative;
  /* display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: center;
    @media (max-width: 1400px) {
      grid-template-columns: auto auto auto;
    } */

  @media (max-width: $phone) {
    padding: 0 0.5rem;
  }

  .cattle-img {
    @include -width(100%, 100%, 2.3rem);
  }

  .blind-box-prev-btn,
  .blind-box-next-btn {
    position: absolute;
    top: 50%;
    @include -font-size(0.3rem, 0.3rem, vw(50));
    color: #fff;
    cursor: pointer;
    z-index: 999;
  }

  .blind-box-prev-btn {
    @include -left(0.2rem, vw(30), vw(-50));
  }

  .blind-box-next-btn {
    right: 0.2rem;
    @include -right(0.2rem, vw(30), vw(-50));
    transform: rotate(180deg);
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bottom-tip {
  @include -width(auto, auto, 7.75rem);
  @include -font-size(0.24rem, 0.24rem, 0.18rem);
  line-height: 0.3rem;
  margin: 0.6rem auto 0.7rem;
}

.back-btn {
  width: 100%;
  @include flexPos(center);
  @include -font-size(0.24rem, 0.24rem, 0.18rem);
  cursor: pointer;
}
</style>
