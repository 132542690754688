<!-- 牛牛盲盒 -->
<script setup>
import { computed, ref, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import $load from '@cps/GlobalLoading';
import { useI18n } from 'vue-i18n';

const $tx = useI18n();
const $route = useRoute();
const router = useRouter();
const appStore = useAppStore();
</script>

<template>
  <div class="blind-box-wrap">
    <img src="https://game.legendranch.app/legendaryranch/banner/merge-banner.png" alt="" class="banner" />

    <!-- 主要内容 -->
    <section class="main">
      <!-- background -->
      <div class="background-wrap">
        <div class="title">{{ $t('farm.1') }}</div>
        <div class="content-wrap">
          {{ $t('halo.1') }}
        </div>
      </div>

      <div class="desinfo-wrap">
        <div class="title">{{ $t('halo.2') }}</div>
        <div class="content-wrap">
          <ul class="rules-info">
            <li>
              {{ $t('halo.3') }}
            </li>
            <li>
              {{ $t('halo.4') }}
            </li>
            <!-- <li>
              {{ $t('halo.5') }}
            </li> -->
            <li>
              {{ $t('halo.6') }}
            </li>
            <li v-html="$t('halo.7')"></li>
          </ul>
          <table>
            <tr>
              <th>{{ $t('farm.37') }}</th>
              <th>{{ $t('igo.3') }}</th>
              <th>{{ $t('claimAssets.15') }}</th>
            </tr>
            <tr>
              <td>{{ $t('claimAssets.12') }}</td>
              <td>0.10%</td>
              <td>20</td>
            </tr>
            <tr>
              <td>{{ $t('home.34') }}</td>
              <td>5.00%</td>
              <td>1,000</td>
            </tr>
            <tr>
              <td>{{ $t('card.18') }}</td>
              <td>10.00%</td>
              <td>2,000</td>
            </tr>
            <tr>
              <td>{{ $t('card.23') }}</td>
              <td>84.90%</td>
              <td>16,990</td>
            </tr>
          </table>
          <ul class="rules-info">
            <li>
              {{ $t('halo.8') }}
            </li>
            <li>
              {{ $t('halo.9') }}
            </li>
            <ul class="sub-rules">
              <li>
                {{ $t('halo.10') }}
              </li>
              <li>
                {{ $t('halo.11') }}
              </li>
              <li>
                {{ $t('halo.12') }}
              </li>
            </ul>
            <li>
              {{ $t('halo.13') }}
            </li>
            <ul class="sub-rules">
              <li>
                {{ $t('halo.14') }}
              </li>
              <li>
                {{ $t('halo.15') }}
              </li>
              <li>
                {{ $t('halo.16') }}
              </li>
            </ul>
            <li>
              {{ $t('halo.17') }}
            </li>
            <li>
              {{ $t('halo.18') }}
            </li>
          </ul>
        </div>
        <div class="btn-wrap">
          <button @click="router.push({ name: 'buyBox' })">{{ $t('halo.19') }}</button>
          <button @click="router.push({ name: 'mergeFragments' })">{{ $t('halo.20') }}</button>
        </div>
      </div>
      <div class="back-btn" @click="router.push({ name: 'cattleMartEvent' })">
        {{ $t('halo.45') }}
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
$mobGap: 0.2rem; // 移动端两端的padding

.blind-box-wrap {
  color: #fff;
  width: 100%;
  height: 100%;
  background-image: url('https://game.legendranch.app/legendaryranch/nft/bg-blindBox-star.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.banner {
  width: 100%;
  position: relative;
  z-index: 9;
  @include -height(2.6rem, 3.6rem, auto);
  object-fit: cover;
}

.bg-blindBox-star {
  width: 100%;
  height: calc(100% - 2rem);
  @include absPos(2rem, '', '', 0);
}

.main {
  width: 100%;
  position: relative;
  padding: 0 $mobGap 0.8rem;
  margin: 0 auto;
  z-index: 9;
}

.desinfo-wrap {
  position: relative;
  @include -width(calc(100vw - 0.3rem), 10.94rem, 10.94rem);
  // @include -height(auto, 13.18rem, 13.18rem);
  @include flexPos(center);
  flex-direction: column;
  margin: 0.53rem auto 0;
  padding: 0.5rem 0;
  @include -font-size(0.24rem, 0.24rem, 0.18rem);
  font-weight: 400;
  line-height: 0.36rem;
  color: #fff;
  @include -media($phone, padding, 1rem 0 1rem);
  backdrop-filter: blur(0.3rem);
  background-image: url('~@img/nft/bg-descrt.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .title {
    font-size: 0.3rem;
    font-weight: bold;
    line-height: 0.48rem;
    margin-bottom: 0.2rem;
  }
  .content-wrap {
    @include -width(calc(100vw - 0.8rem), 9rem, 9.7rem);
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    text-align: left;
  }
}
:deep(a) {
  color: #6298db !important;
  display: initial !important;
}
table {
  @include -width(calc(100vw - 0.8rem), 5rem, 5rem);
  margin: 0.2rem auto;

  tr {
    &:last-child {
      border-bottom: none;
    }
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.4);
  }

  th {
    @include -font-size(0.3rem, 0.3rem, 0.24rem);
    text-align: center;
    @include -width(4rem, 4rem, 3rem);
    height: 0.5rem;
  }

  td {
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    text-align: center;
    @include -width(4rem, 4rem, 3rem);
    height: 0.5rem;
  }

  td:first-child {
    text-align: left;
  }
}

.sub-rules {
  padding-left: 0.2rem;
}

.btn-wrap {
  @include -width(calc(100vw - 0.8rem), 9rem, 9rem);
  @include flexPos(space-around, center);
  margin: 0.5rem auto 0;

  button {
    @include -width(2.5rem, 2.9rem, 2.9rem);
    @include -height(auto, 0.7rem, 0.7rem);
    @include flexPos(center);
    color: #6298db;
    @include -font-size(0.18rem, 0.24rem, 0.24rem);
    font-weight: bold;
    padding: 0.1rem 0.2rem;
    @include -media($pc, padding, 0);
    background-image: url('~@img/nft/bg-confirm-btn.png');
    background-size: 100% 100%;
    background-color: transparent;
    background-repeat: no-repeat;
  }
}

.background-wrap {
  position: relative;
  @include -width(calc(100vw - 0.3rem), 10.94rem, 10.94rem);
  @include -height(auto, 3.47rem, 3.47rem);
  @include flexPos(center);
  flex-direction: column;
  margin: 0 auto;
  font-size: 0.3rem;
  font-weight: bold;
  color: #fff;
  @include -media($phone, padding, 1rem 0 1rem);
  backdrop-filter: blur(0.3rem);
  background-image: url('~@img/nft/bg-top.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .content-wrap {
    @include -width(calc(100vw - 0.8rem), 9rem, 9.7rem);
    @include -font-size(0.24rem, 0.24rem, 0.18rem);
    text-align: center;
    font-weight: 400;
    line-height: 0.36rem;
  }
}

.back-btn {
  width: 100%;
  margin-top:0.2rem;
  @include flexPos(center);
  @include -font-size(0.24rem, 0.24rem, 0.18rem);
  cursor: pointer;
}
</style>
