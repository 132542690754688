<script setup>
import { defineProps, defineEmits, ref, computed, watchEffect } from 'vue';
import { showRewardPop, setShowRewardPop } from './usePop';

const props = defineProps({
  title: {
    type: String,
    default: 'blindBox.48'
  },
  rewardList: {
    type: Array,
    default: []
  }
});

/**
 * 关闭按钮
 */
function handleClose() {
  setShowRewardPop(false);
}
</script>

<template>
  <van-popup v-model:show="showRewardPop">
    <div class="pop-wrap">
      <div class="head-wrap">
        <span>{{ $t('dialog.13') }}</span>
        <div class="center-label">{{ $t('dialog.14') }}</div>
      </div>
      <div class="reward-img-wrap">
        <div class="img-block" v-for="item in props.rewardList">
          <img :src="item.image" alt="" class="reward-img" />
          <div class="label">{{ $t(item.name) }}</div>
          <div class="val">{{ `x ${item.amount}` }}</div>
        </div>
      </div>
      <button class="confirm-btn" @click="handleClose">OK</button>
    </div>
  </van-popup>
</template>

<style lang="scss" scoped>
@import './index.scss';
</style>
